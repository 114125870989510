
































































































































































































































































































@font-face {
  font-family: 'helvetica-neue';
  src: url('~@/assets/fonts/helvetica-neue.eot');
  src: url('~@/assets/fonts/helvetica-neue.eot?#iefix') format('embedded-opentype'),
       url('~@/assets/fonts/helvetica-neue.woff2') format('woff2');
  src: url('~@/assets/fonts/HelveticaNeue.svg#HelveticaNeue') format('svg'),
       url('~@/assets/fonts/HelveticaNeue.ttf') format('truetype'),
       url('~@/assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-neue';
  src: url('~@/assets/fonts/helvetica-neue-bold.eot');
  src: url('~@/assets/fonts/helvetica-neue-bold.eot?#iefix') format('embedded-opentype'),
       url('~@/assets/fonts/helvetica-neue-bold.woff2') format('woff2');
  src: url('~@/assets/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg'),
       url('~@/assets/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
       url('~@/assets/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

img {
  max-width: 100%;
}

.contrato {
  font-family: 'helvetica-neue';
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &-wave {
      margin-top: -150px;

      @media (min-width: 1550px) {
        margin-top: -250px;
      }

      @media (max-width: 990px) {
        margin-top: -110px;
      }

      @media (max-width: 815px) {
        margin-top: -60px;
      }

      @media (max-width: 660px) {
        margin-top: 0;
      }
    }

    &-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-60%);
      

      @media (max-width: 1270px) {
        width: 200px;
      }

      @media (max-width: 500px) {
        width: 130px;
      }
    }
  }

  &-container {
    min-height: calc(100vh - 332px);
    // background: url('~@/assets/img/detail_azul_rodape.png') no-repeat top center / cover;
    position: relative;
    padding-bottom: 355px;

    @media (max-width: 1130px) {
      padding-bottom: 240px;
    }

    @media (max-width: 710px) {
      padding-bottom: 130px;
    }

    @media (max-width: 415px) {
      padding-bottom: 90px;
    }

    &:before {
      content: '';
      width: 100%;
      height: 500px;
      background: url('~@/assets/img/detail_azul_rodape.png') no-repeat top center / cover;
      position: absolute;
      bottom: 0;
    }
  }

  &-article {
    max-width: 883px;
    margin: 0 auto;
    padding: 30px 50px 100px 50px;
    position: relative;
    z-index: 1;
    background-color: #FFF;

    @media (max-width: 510px) {
      padding: 30px 20px 50px 20px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;

      &-logo {
        @media (max-width: 600px) {
          width: 130px;
        }
      }

      &-cabecalho {
        font-weight: bold;
        width: 100%;
        text-align: right;
        margin-left: 60px;
        border-bottom: 1px solid #000;
        font-size: 14px;
        text-transform: uppercase;

        @media (max-width: 600px) {
          font-size: 12px;
          margin-left: 20px;

        }
      }
    }

    &-wrapper {
      max-height: 400px;
      overflow-y: auto;
      padding: 20px 10px 20px 0;
      margin: 20px 0;
      text-align: justify;

      &::-webkit-scrollbar-track {
        background-color: transparent !important;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background: transparent !important;
      }
      &::-webkit-scrollbar-thumb {
        background: #1cb4ff;
        border-radius: 25px;
      }

      dd {
        margin-left: 15px;

        &.nivel3 {
          margin-left: 53px;
          padding-left: 46px;
        }
      }

      dd, dt {
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;

        b {
          position: absolute;
          left: 0;
        }
      }

      dt {
        padding-left: 25px;
      }

      ol {
        &.nivel1 {
          margin-left: 50px;
          margin-bottom: 20px;
        }

        &.nivel2 {
          margin-left: 40px;
          margin-bottom: 20px;

        }
      }

      li {
        dd {
          margin-bottom: 0;
          margin-left: 0px;
          padding-left: 20px;

          span {
            position: absolute;
            left: 0;
          }
        }
      }
    }


    &-btn {
      background-image: linear-gradient(to right, #1cd3ff 24%, #1cb4ff);
      font-size: 14px;
      font-weight: bold;
      color: #FFF;
      padding: 10px 20px;
      border-radius: 25px;
      text-decoration: none;
      display: inline-block;
      margin-top: 50px;
    }


    h1 {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;
      line-height: 1.2;
      margin-bottom: 30px;
      font-size: 18px;
    }



  }

  &-footer {
    height: 390px;
    position: absolute;
    width: 100%;
    bottom: 0;
    &-maozinha {
      position: absolute;
      z-index: 3;
      right: 840px;
      bottom: -6px;

      @media (max-width: 1130px) {
        right: 700px;
        width: 190px;
      }

      @media (max-width: 910px) {
        right: 500px;
      }

      @media (max-width: 715px) {
        right: 280px;
        width: 110px;
      }

      @media (max-width: 415px) {
        right: 185px;
        width: 85px;
      }
    }

    &-fundo {
      position: absolute;
      right: 0px;
      bottom: -6px;
      z-index: 2;

      @media (max-width: 1130px) {
        width: 710px;
      }

      @media (max-width: 910px) {
        width: 510px;
      }

      @media (max-width: 715px) {
        width: 300px;
      }

      @media (max-width: 415px) {
        width: 200px;
      }
    }
  }
}
